
import { useQuery } from 'react-query';
import { useParams, useLocation } from 'react-router-dom';
import React, { useEffect, useLayoutEffect } from 'react';

import PageLayout from "../../../layout/PageLayout";
import PromoBanner from '../PromoBanner';
import FilterComponent from "../../../filter/FilterComponent";
import LoadingScreen from "../../../loadingScreen/LoadingScreen";
import MissingPage from "../../../errorMessages/MissingPage";
import { capitalizeString } from "../../../../../utilities/TextManipulation";
import { captureAnalytics } from "../../../../../utilities/Analytics";
// Add these new imports
import { PromoApplicationData} from "../types";
import { getPromoApplicationData} from "../mockPromoData"; // Adjust the path based on your file structure

function PromoApplicationTemplate() {
    const { application } = useParams<{ application: string }>();
    const location = useLocation();

    // Modified to fetch promo-specific application data
    const {
        data: promoData,
        isLoading,
        isError
    } = useQuery<PromoApplicationData>(
        ['promo-application', application],
        () => getPromoApplicationData(application || ''),  // We'll create this function
        {
            staleTime: 0,
            enabled: !!application,
            onError: (error) => {
                console.error('Error fetching promo application data:', error);
            }
        }
    );

    // Existing effects for scroll and analytics
    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    }, [location.pathname]);

    useEffect(() => {
        if (application) {
            captureAnalytics('pageview', location.pathname, `promo-${application}`);
        }
    }, [location.pathname, application]);

    if (!application) {
        return <MissingPage />;
    }

    return (
        <PageLayout>
            {isLoading && <LoadingScreen />}
            {isError && <MissingPage />}
            {promoData && (
                <>
                    <PromoBanner
                        image={promoData.banner.image}
                        text={promoData.banner.text}
                        link={promoData.banner.link}
                        color={promoData.banner.color}
                    />

                    <FilterComponent
                        title={`${capitalizeString(application)} Promotions`}
                        itemGroup={application}
                        isPromo
                    />
                </>
            )}
        </PageLayout>
    );
}

export default PromoApplicationTemplate;