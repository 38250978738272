import React from 'react';
import {
    Container,
    Grid,
    Card,
    CardMedia,
    Typography,
    Box,
    useTheme,
    useMediaQuery
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { PromoLayout, SECTION_TYPES } from '../types';
import { mockPromoLayouts } from '../mockPromoData';
import PromoBanner from '../PromoBanner'

// Styled components
const StyledBanner = styled(Box)(({ theme }) => ({
    width: '100%',
    height: '200px',
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: theme.spacing(4),
    cursor: 'pointer',
    transition: 'transform 0.2s',
    '&:hover': {
        transform: 'scale(1.01)'
    }
}));

const StyledCard = styled(Card)(() => ({
    position: 'relative',
    height: '250px',
    overflow: 'hidden',
    borderRadius: 0,
    transition: 'transform 0.3s ease',
    '&:hover': {
        transform: 'scale(1.02)',
        cursor: 'pointer'
    }
}));

const CategoryTitle = styled(Typography)(({ theme }) => ({
    marginTop: theme.spacing(12),
    marginBottom: theme.spacing(2),
    fontWeight: 600,
    fontSize: '1.5rem',
}));

const OverlayText = styled(Box)(() => ({
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    padding: '15px',
    background: 'linear-gradient(transparent, rgba(0,0,0,0.7))',
    color: 'white',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
}));

const PromoContent: React.FC = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    // Filter active items using new constants
    const activeItems = mockPromoLayouts.filter(item => item.is_active && item.is_live);

    // Get main banner and categories using new constants
    const mainBanner = activeItems.find(item => item.section_type === SECTION_TYPES.MAIN_BANNER);
    const categories = activeItems.filter(item => item.section_type === SECTION_TYPES.CATEGORY);

    const handleBannerClick = (link: string): void => {
        window.location.href = link;
    };

    const handleCategoryClick = (link: string): void => {
        window.location.href = link;
    };

    // Group categories by application
    const categorySections = {
        Fabric: categories.filter(cat => cat.application === 'fabric'),
        Wallpaper: categories.filter(cat => cat.application === 'wallpaper'),
        Rugs: categories.filter(cat => cat.application === 'rugs'),
        Homeware: categories.filter(cat => cat.application === 'homeware')
    };

    const renderCategorySection = (title: string, categories: PromoLayout[]) => (
        <Box>
            <CategoryTitle variant="h5">
                {title}
            </CategoryTitle>
            <Grid container spacing={3}>
                {categories.map((category) => (
                    <Grid
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        key={category.id || category.category}
                    >
                        <StyledCard onClick={() => handleCategoryClick(category.link)}>
                            <CardMedia
                                component="img"
                                height="250"
                                image={category.image}
                                alt={category.category || ''}
                                sx={{ objectFit: 'cover' }}
                            />
                            <OverlayText style={{ color: category.color }}>
                                <Typography
                                    variant="h6"
                                    sx={{
                                        fontWeight: 'normal',
                                        textAlign: 'center',
                                        width: '100%'
                                    }}
                                >
                                    {category.text}
                                </Typography>
                            </OverlayText>
                        </StyledCard>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );

    return (
        <Container maxWidth="lg" sx={{ py: 4 }}>
            {mainBanner && (
                <PromoBanner
                    image={mainBanner.image}
                    text={mainBanner.text}
                    link={mainBanner.link}
                    color={mainBanner.color}
                />
            )}

            {Object.entries(categorySections).map(([title, items]) =>
                items.length > 0 && renderCategorySection(title, items)
            )}
        </Container>
    );
};

export default PromoContent;