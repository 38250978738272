import React from 'react';
import { Box } from "@mui/material";

interface PromoBannerProps {
    image: string;
    text: string;
    link: string;
    color: string;  // For text color from promo_layout table
}

const PromoBanner: React.FC<PromoBannerProps> = ({
                                                     image,
                                                     text,
                                                     link,
                                                     color = '#FFFFFF'  // Default white text
                                                 }) => {
    const handleClick = () => {
        if (link) {
            window.location.href = link;
        }
    };

    return (
        <Box
            sx={{
                width: "100%",
                position: "relative",
                overflow: "hidden",
                paddingTop: "56.25%", // 16:9 Aspect Ratio
                cursor: link ? "pointer" : "default",
            }}
            onClick={handleClick}
        >
            {/* Image */}
            <img
                style={{
                    width: "100%",
                    height: "100%",
                    position: "absolute",
                    top: 0,
                    left: 0,
                    objectFit: "cover",
                }}
                src={image}
                alt=""
            />

            {/* Text Overlay */}
            {text && (
                <Box
                    sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        textAlign: "center",
                        color: color,
                        padding: "20px",
                        background: "rgba(0, 0, 0, 0.3)", // Semi-transparent background
                        borderRadius: "4px",
                        maxWidth: "80%"
                    }}
                >
                    <h1
                        style={{
                            fontSize: "2.5rem",
                            fontWeight: "bold",
                            margin: 0,
                            color: color
                        }}
                    >
                        {text}
                    </h1>
                </Box>
            )}
        </Box>
    );
};

export default PromoBanner;