// types.ts

// Constants
export const SECTION_TYPES = {
    MAIN_BANNER: 'mainBanner',
    CATEGORY: 'category'
} as const;

export const CONTENT_TYPES = {
    BANNER: 'banner',
    CATEGORY: 'category'
} as const;

// Base Types
export interface BasePromoEntity {
    id?: string;
    is_active: boolean;
    is_live: boolean;
    created_at?: string;
    updated_at?: string;
}

// Database Schema Types
export interface PromoLayout extends BasePromoEntity {
    section_type: typeof SECTION_TYPES[keyof typeof SECTION_TYPES];
    category?: string;
    application?: string;
    content_type: typeof CONTENT_TYPES[keyof typeof CONTENT_TYPES];
    text: string;
    link: string;
    color: string;
    image: string;
    url?: string;
}

export interface ProductPromo extends BasePromoEntity {
    stock_link: number;
    original_price_inc_vat: number;
    promo_price_inc_vat: number;
    start_date: string;
    end_date: string;
}

// Frontend Display Types
export interface Category {
    id: number;
    name: string;
    image: string;
    discount: string;
    link: string;
}

export interface MainBanner {
    title: string;
    image: string;
    link: string;
}

// API Response Types
export interface PromoResponse {
    layout: PromoLayout[];
    products?: ProductPromo[];
    category?: string;
}

// Page-Specific Data Types
export interface PromoPageData {
    current: {
        type: 'single' | 'multiple';
        mainBanner: MainBanner;
        categories: Category[];
    }
}

export interface ApplicationPageData {
    banner: PromoLayout | undefined;
    products: ProductPromo[];
    category?: string;
}

export interface PromoApplicationData {
    banner: {
        image: string;
        text: string;
        link: string;
        color: string;
    };
    application: string;
    start_date: string;
    end_date: string;
}