import {
    PromoLayout,
    ProductPromo,
    SECTION_TYPES,
    CONTENT_TYPES,
    PromoPageData,
    PromoApplicationData
} from './types';

// Mock Promo Layout Data
export const mockPromoLayouts: PromoLayout[] = [
    // Main Banner
    {
        id: 'banner-1',
        section_type: SECTION_TYPES.MAIN_BANNER,
        content_type: CONTENT_TYPES.BANNER,
        text: 'SAVE UP TO 50% | 22 MARCH 2024 - 2 APRIL 2025',
        image: 'https://i.ibb.co/1XMkt3x/promo-landing-page.png',
        link: '/promo/',
        color: '#FFFFFF', // White text for main banner
        is_active: true,
        is_live: true
    },
    // Categories
    {
        id: 'cat-1',
        section_type: SECTION_TYPES.CATEGORY,
        category: 'Dual Purpose',
        application: 'fabric',
        content_type: CONTENT_TYPES.CATEGORY,
        text: 'UP TO 50% OFF DUALPURPOSE',
        image: 'https://i.ibb.co/wh2HG8X/HX-Web-banners-fabric-Landing-Page-2800x1600px-Nov24.jpg',
        link: '/category/dual-purpose',
        color: '#FF0000', // Red text for this category
        is_active: true,
        is_live: true
    },
    {
        id: 'cat-2',
        section_type: SECTION_TYPES.CATEGORY,
        category: 'Upholstery',
        application: 'fabric',
        content_type: CONTENT_TYPES.CATEGORY,
        text: 'UP TO 30% OFF UPHOLSTERY',
        image: 'https://i.ibb.co/R2PzGxS/Banner-Collection-Cdards.jpg',
        link: '/category/upholstery',
        color: '#000000', // Black text for this category
        is_active: true,
        is_live: true
    },
    {
        id: 'cat-3',
        section_type: SECTION_TYPES.CATEGORY,
        category: 'Curtaining',
        content_type: CONTENT_TYPES.CATEGORY,
        text: 'UP TO 40% OFF CURTAINING',
        image: 'https://i.ibb.co/w7Z3jKt/Northen-Lights.jpg',
        link: '/category/curtaining',
        color: '#333333', // Dark gray text
        is_active: true,
        is_live: true
    },
    {
        id: 'cat-4',
        section_type: SECTION_TYPES.CATEGORY,
        category: 'Wallpaper',
        content_type: CONTENT_TYPES.CATEGORY,
        text: 'UP TO 50% OFF WALLPAPER',
        image: 'https://i.ibb.co/9TQr4mk/wallpaper.jpg',
        link: '/category/wallpaper',
        color: '#FFFFFF', // White text
        is_active: true,
        is_live: true
    }
];

// Mock Product Promos Data
export const mockProductPromos: ProductPromo[] = [
    {
        id: 'promo-1',
        stock_link: 12345,
        original_price_inc_vat: 299.99,
        promo_price_inc_vat: 249.99,
        start_date: '2024-03-22T00:00:00Z',
        end_date: '2025-04-02T23:59:59Z',
        is_active: true,
        is_live: true
    },
    {
        id: 'promo-2',
        stock_link: 12346,
        original_price_inc_vat: 199.99,
        promo_price_inc_vat: 159.99,
        start_date: '2024-03-22T00:00:00Z',
        end_date: '2025-04-02T23:59:59Z',
        is_active: true,
        is_live: true
    },
    {
        id: 'promo-3',
        stock_link: 12347,
        original_price_inc_vat: 399.99,
        promo_price_inc_vat: 299.99,
        start_date: '2024-03-22T00:00:00Z',
        end_date: '2025-04-02T23:59:59Z',
        is_active: true,
        is_live: true
    }
];

// Helper Functions
export const getPromoPageData = (): PromoPageData => ({
    current: {
        type: 'multiple',
        mainBanner: {
            title: mockPromoLayouts[0].text,
            image: mockPromoLayouts[0].image,
            link: mockPromoLayouts[0].link
        },
        categories: mockPromoLayouts
            .filter(layout => layout.section_type === SECTION_TYPES.CATEGORY)
            .map(layout => ({
                id: Number(layout.id?.replace('cat-', '')),
                name: layout.category || '',
                image: layout.image,
                discount: layout.text,
                link: layout.link
            }))
    }
});

export const getPromoApplicationData = async (application: string): Promise<PromoApplicationData> => {
    // Find the banner for the specific application
    const applicationBanner = mockPromoLayouts.find(
        layout => layout.section_type === SECTION_TYPES.CATEGORY &&
            layout.application?.toLowerCase() === application.toLowerCase()
    );

    if (!applicationBanner) {
        throw new Error(`No promotion found for application: ${application}`);
    }

    // Return data in the format expected by the component
    return {
        banner: {
            image: applicationBanner.image,
            text: applicationBanner.text,
            link: applicationBanner.link,
            color: applicationBanner.color
        },
        application: application,
        start_date: '2024-03-22T00:00:00Z',
        end_date: '2025-04-02T23:59:59Z'
    };
};